import { LoadingBackground } from '@core/Components/LoadingBackground/LoadingBackground';
import { Error } from '@gds/Icons/Paths/Error';
import { SuccessTickCircle } from '@gds/Icons/Paths/SuccesTickCircle';
import { Typography } from '@gds/Typography/Typography';

import { hasKwankoCookieApprovedAtom } from '@tracking/Atoms/HasKwankoCookieApproved.atom';
import { useAtomValue } from 'jotai';

import { StatusDisplayProps } from './StatusDisplay.entity';

import styles from './StatusDisplay.module.css';

export const StatusDisplay = ({
  messages,
  type,
  kwankoTrackingId,
  kwankoListingId,
}: StatusDisplayProps) => {
  const Icon = type === 'success' ? SuccessTickCircle : Error;

  const hasKwankoCookie = useAtomValue(hasKwankoCookieApprovedAtom);

  if (hasKwankoCookie && window.KWKUniJS !== undefined && kwankoTrackingId && kwankoListingId) {
    KWKUniJS.conversion({
      mclic: `${kwankoTrackingId || ''}`,
      argann: `${kwankoListingId || ''}`,
    });
    KWKUniJS.ptag({
      zone: 'lead_confirmation',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      id_lead: `${kwankoListingId || ''}`,
    });
  }

  return (
    <>
      <div className={styles.resultMessageWrapper}>
        {type === 'loading' ? <LoadingBackground /> : <Icon fontSize={64} />}
        <Typography variant="body1" className={styles.resultHeader}>
          {messages.headline}
        </Typography>
      </div>
      {messages.description && (
        <Typography variant="body3" className={styles.resultDescription}>
          {messages.description}
        </Typography>
      )}
    </>
  );
};
