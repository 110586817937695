import { ChangeEvent } from 'react';

import { getClientSideCookieValue } from '@cookies/GetClientSideCookieValue';
import { valuationAtom } from '@core/Atoms/Valuation/Valuation.atom';
import { LOCALE } from '@core/Constants/Locale.constants';
import { Locale } from '@core/Entities/Locale/Locale.entity';
import { getFormattedCurrency } from '@core/Utils/Intl/GetFormattedCurrency';
import { Accordion } from '@gds/Accordion/Accordion';
import Checkbox from '@gds/Checkbox/Checkbox';
import Grid from '@gds/Grid/Grid';
import { Input } from '@gds/Input/Input';
import { Typography } from '@gds/Typography/Typography';
import { useAtomValue, useSetAtom } from 'jotai';

import { AccordionCheckbox } from '../../AccordionCheckbox/AccordionCheckbox';
import { PartExchangeProps } from '../Enrichment.entity';

import styles from './PartExchange.module.css';

export const PartExchange = ({
  strings,
  inputProps,
  shouldShowCheckboxToggle,
  id,
}: PartExchangeProps) => {
  const valuationAtomValue = useAtomValue(valuationAtom);
  const {
    partExchangeAccordionHeader: accordionHeader,
    partExchangeCaption: caption,
    partExchangeCarValue: carValue,
    partExchangeErrorMileage: errorMileage,
    partExchangeMileageLabel: mileageLabel,
    partExchangeNotYourCar: notYourCar,
    partExchangePreFilledCaption: preFilledCaption,
    partExchangePreFilledCheckbox: preFilledCheckbox,
    partExchangeRegistrationLabel: registrationLabel,
    partExchangeRequiredReg: requiredReg,
    partExchangeRequiredMileage: requiredMileage,
    partExchangeYourCar: yourCar,
    enrichmentTextHeaderClose: headerClose,
    enrichmentTextHeaderOpen: headerOpen,
  } = strings;
  const {
    touched,
    errors,
    ids,
    handleChange,
    handleBlur,
    values: { isSendMyValuationChecked, registration, mileage },
  } = inputProps;
  const registrationId = ids.find((field: string) => field === 'registration');
  const valuationId = ids.find((field: string) => field === 'valuationChkBox');
  const mileageId = ids.find((field: string) => field === 'mileage');

  const customToggleTextHeader = {
    openText: headerOpen,
    closeText: headerClose,
  };
  const locale = getClientSideCookieValue(LOCALE) as Locale;
  const setValuationAtom = useSetAtom(valuationAtom);

  const handleSendMyValuationChange = (event: {
    target: { checked: boolean | ((prevState: boolean) => boolean) };
  }) => {
    handleChange(valuationId, event.target.checked);
  };

  const handleNotYourCarClick = () => {
    setValuationAtom(null);
    handleChange(valuationId, false);
  };

  const handleChangeRegMileage = (e: ChangeEvent<HTMLInputElement>, name: 'reg' | 'mileage') => {
    if (name === 'reg') {
      const inputValue = e.target.value;
      handleChange(registrationId, inputValue);
    }
    if (name === 'mileage') handleChange(mileageId, e.target.value);
  };

  const getMileageError = () => {
    if (registration && !mileage) return requiredMileage;
    if (registration && !mileage?.toString().match(/^(\d)+$/)) return errorMileage;

    return undefined;
  };

  const content =
    valuationAtomValue && valuationId ? (
      <Grid.Row className={styles.partExchangeWrapper}>
        <Grid.Col width={{ tablet: 12, mobile: 12 }}>
          <Typography variant="body4" className={styles.caption}>
            {preFilledCaption}
          </Typography>
        </Grid.Col>

        <Grid.Col width={{ tablet: 12, mobile: 12 }} className={styles.highlightBackground}>
          <div className={styles.valuationContent}>
            <div className={styles.checkboxContainer}>
              <Checkbox
                checked={isSendMyValuationChecked as boolean}
                onChange={handleSendMyValuationChange}
              />
              <Typography variant="body3">{preFilledCheckbox}</Typography>
            </div>
            <div className={styles.vehicleInformation}>
              <Typography variant="subheading1">{yourCar} </Typography>
              <Typography variant="body4">
                {valuationAtomValue.vehicle.make} {valuationAtomValue.vehicle.model}
                {', '}
                {valuationAtomValue.vehicle.registration}
              </Typography>
            </div>
            <div className={styles.lastLineContainer}>
              <div className={styles.vehicleInformation}>
                <Typography variant="subheading1">{carValue}</Typography>
                <Typography variant="body4">
                  {getFormattedCurrency(valuationAtomValue.valuation.low, locale)}
                  {' - '}
                  {getFormattedCurrency(valuationAtomValue.valuation.high, locale)}
                </Typography>
              </div>
              <div className={styles.notYourCar}>
                <button onClick={() => handleNotYourCarClick()}>
                  <Typography variant="buttonLink3">{notYourCar}</Typography>
                </button>
              </div>
            </div>
          </div>
        </Grid.Col>
      </Grid.Row>
    ) : (
      <Grid.Row className={styles.partExchangeWrapper}>
        <Grid.Col width={{ tablet: 6, mobile: 12 }}>
          <Typography variant="subheading4">{caption}</Typography>
          <div className={styles.flex}></div>
        </Grid.Col>
        <Grid.Col width={{ tablet: 6, mobile: 12 }} className={styles.inputFieldsWrapper}>
          {registrationId && (
            <Input
              dataTestId="registration"
              placeholder={registrationLabel}
              onChange={e => handleChangeRegMileage(e, 'reg')}
              onBlur={() => handleBlur(registrationId)}
              error={
                (mileage && registration === '' && requiredReg) ||
                (touched[registrationId] && errors[registrationId])
              }
              value={registration}
              id={`${id}_resistration`}
              fullWidth
            />
          )}
          {mileageId && (
            <Input
              dataTestId="mileage"
              placeholder={mileageLabel}
              onChange={e => handleChangeRegMileage(e, 'mileage')}
              value={mileage}
              max="999999"
              min="0"
              name="mileage"
              pattern="\d*"
              type="number"
              fullWidth
              id={`${id}_mileage`}
              error={getMileageError()}
            />
          )}
        </Grid.Col>
      </Grid.Row>
    );

  return (
    <>
      {shouldShowCheckboxToggle ? (
        <AccordionCheckbox title={accordionHeader} id={id}>
          {content}
        </AccordionCheckbox>
      ) : (
        <Accordion title={accordionHeader} customtoggletextheader={customToggleTextHeader}>
          {content}
        </Accordion>
      )}
    </>
  );
};
