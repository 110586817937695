export const validateFullName = (fullName: string | undefined): boolean => {
  if (!fullName || fullName?.length < 2) {
    return false;
  }

  const nameParts = fullName.trim().split(' ');

  if (nameParts.length < 2) {
    return false;
  }

  const firstName = nameParts[0];
  const lastName = nameParts.slice(1).join(' ');
  const lastNameParts = lastName.replace(/\s/g, '');

  if (firstName.length > 2 && lastNameParts.length > 2) {
    return true;
  }
  return false;
};
