import createSvgIcon from '../Utils/CreateSvgIcon';

export const HeartDefaultRac = createSvgIcon(
  <path
    clipRule="evenodd"
    d="M2.55566 9.5808C2.55567 6.49861 5.05428 4 8.13647 4C9.63656 4 10.9981 4.59232 12.0001 5.55382C13.0022 4.59232 14.3637 4 15.8637 4C18.9459 4 21.4445 6.49861 21.4446 9.5808C21.4446 10.1569 21.357 10.7142 21.1937 11.2393C20.4913 13.6367 18.4212 15.9475 16.5686 17.5998C15.6222 18.4439 14.6903 19.1533 13.9411 19.6566C13.5678 19.9074 13.2284 20.1148 12.9474 20.2636C12.8079 20.3375 12.6687 20.4045 12.5381 20.4555C12.4395 20.494 12.2328 20.5707 12.0001 20.5707C11.7674 20.5707 11.5607 20.494 11.4621 20.4555C11.3315 20.4045 11.1923 20.3375 11.0529 20.2636C10.7718 20.1148 10.4324 19.9074 10.0591 19.6566C9.30993 19.1533 8.37807 18.4439 7.43165 17.5998C5.57905 15.9475 3.50893 13.6367 2.80651 11.2393C2.64325 10.7142 2.55566 10.1569 2.55566 9.5808ZM8.13647 5.88889C6.09748 5.88889 4.44456 7.54181 4.44455 9.5808C4.44455 9.96671 4.50344 10.3371 4.61205 10.6844L4.61478 10.6931L4.61734 10.7019C5.16048 12.5646 6.88398 14.5803 8.68893 16.1901C9.57241 16.9781 10.4363 17.6344 11.1125 18.0887C11.4518 18.3167 11.7325 18.4861 11.9369 18.5944C11.9596 18.6064 11.9806 18.6173 12.0001 18.6271C12.0196 18.6173 12.0406 18.6064 12.0633 18.5944C12.2677 18.4861 12.5484 18.3167 12.8877 18.0887C13.5639 17.6344 14.4278 16.9781 15.3113 16.1901C17.1162 14.5803 18.8397 12.5646 19.3829 10.7019L19.3854 10.6931L19.3882 10.6844C19.4968 10.3371 19.5557 9.96671 19.5557 9.5808C19.5557 7.54181 17.9027 5.88889 15.8637 5.88889C14.5805 5.88889 13.4496 6.5428 12.7866 7.53998L12.0001 8.72284L11.2136 7.53998C10.5506 6.5428 9.41971 5.88889 8.13647 5.88889ZM11.8233 18.7057C11.8233 18.7057 11.8282 18.7037 11.8371 18.7013C11.8275 18.7047 11.8233 18.7057 11.8233 18.7057ZM12.1631 18.7013C12.172 18.7037 12.177 18.7057 12.177 18.7057C12.177 18.7057 12.1727 18.7047 12.1631 18.7013Z"
    fillRule="evenodd"
    color="#f95108"
  />,
  'HeartDefault',
  '0 0 24 24',
);
