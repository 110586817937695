import { Vehicle } from '@core/Entities/Vehicle/Vehicle.entity';

export const getDealerNames = (vehicles: Vehicle[]) => {
  const dealerNames = vehicles.map(
    (vehicle: Vehicle) => vehicle.dealer.displayName || vehicle.dealer.name,
  );
  const uniqueDealerNames = [...new Set(dealerNames)];
  if (uniqueDealerNames.length === 0) return '';
  if (uniqueDealerNames.length === 1) return uniqueDealerNames[0];

  const last = uniqueDealerNames.pop();
  const result = `${uniqueDealerNames.join(', ')} and ${last}`;
  return result;
};
