import { getClientSideCookieValue } from '@cookies/GetClientSideCookieValue';
import { LOCALE } from '@core/Constants/Locale.constants';
import { Locale } from '@core/Entities/Locale/Locale.entity';
import Checkbox from '@gds/Checkbox/Checkbox';
import { Info } from '@gds/Icons/Paths/Info';
import Tooltip from '@gds/Tooltip/Tooltip';
import { Typography } from '@gds/Typography/Typography';

import { CheckboxProps } from '../MultiLeadCheckboxes/MultiLeadCheckboxes.entity';
import { getCheckboxLabel, getInfoPopOverText } from '../Utils/getOneClickChkboxData';

import { PrivacyPolicyLink } from './PrivacyPolicyLink/PrivacyPolicyLink';

import styles from '../OneClickCheckbox/OneClickCheckbox.module.css';

export const ONECLICK_TERMS_LINK = {
  uk: '/uk/multi-submit-terms',
  fr: '/fr/multi-submit-terms',
  // change de later
  de: '/de/multi-submit-terms',
};

export const OneClickDriftrockCheckbox = ({
  strings,
  inputProps,
  isChecked,
  similarVehicles,
}: CheckboxProps) => {
  if (!similarVehicles?.length) return null;

  const { id: oneClickDriftrockChkBoxId, handleChange } = inputProps;
  const locale: Locale = getClientSideCookieValue(LOCALE);
  const { oneClickTerms } = strings;

  const infoPopOverText = getInfoPopOverText(strings, similarVehicles);
  const checkboxLabel = getCheckboxLabel(strings, similarVehicles);
  return (
    <label
      htmlFor="one-click-driftrock-similarcars"
      aria-labelledby="one-click-driftrock-similarcars"
      className={styles.oneClickCheckbox}
    >
      <Checkbox
        id="one-click-driftrock-similarcars"
        checked={isChecked}
        onChange={() => null}
        onClick={() => handleChange(oneClickDriftrockChkBoxId, !isChecked)}
      />
      <Typography variant="subheading4" Component="span">
        {checkboxLabel}{' '}
        <Typography
          variant="buttonLink1"
          Component="a"
          className={styles.termHref}
          // @ts-expect-error - href
          href={ONECLICK_TERMS_LINK[locale]}
          target="_blank"
        >
          {oneClickTerms}
        </Typography>
        <Tooltip
          className={styles.tooltip}
          dialogId="oneclk-driftrock-chkbk-popover"
          extraText={<PrivacyPolicyLink />}
          text={infoPopOverText}
        >
          <Info fontSize={24} />
        </Tooltip>
      </Typography>
    </label>
  );
};
