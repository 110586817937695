import { addFavouritesSchema } from './AddFavouritesSchema';
import { AddFavouritesEvent } from './AddFavouritesEvent';

type Props = {
  favouriteSource: string;
  listingId: string | null;
};

export const addFavouritesToEvent = ({ favouriteSource, listingId }: Props): AddFavouritesEvent => {
  const event: AddFavouritesEvent = {
    schema: addFavouritesSchema.ref,
    data: {
      favourite_source: favouriteSource,
      listing_id: listingId,
    },
  };

  return event;
};
