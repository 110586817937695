import { LeadSubmitEvent } from './LeadSubmitEvent';
import { leadSubmitSchema } from './LeadSubmitSchema';

type Props = {
  leadType: string;
  listingId: string | null;
  leadId: string;
  captiveId?: string;
};

export const leadSubmitToEvent = ({
  leadType,
  leadId,
  listingId,
  captiveId,
}: Props): LeadSubmitEvent => {
  const event: LeadSubmitEvent = {
    schema: leadSubmitSchema.ref,
    data: {
      lead_type: leadType,
      lead_id: leadId,
      listing_id: listingId,
      captive_id: captiveId,
    },
  };

  return event;
};
