import { reservationStartSchema } from './ReservationStartSchema';
import { LeadStartEvent } from '../../Lead/LeadStart/LeadStartEvent';

type Props = {
  leadType: string;
  listingId: string | null;
};

export const reservationStartToEvent = ({ leadType, listingId }: Props): LeadStartEvent => {
  const event: LeadStartEvent = {
    schema: reservationStartSchema.ref,
    data: {
      lead_type: leadType,
      listing_id: listingId,
    },
  };

  return event;
};
