import { SkeletonLoader } from '@core/Components/Loading/SkeletonLoader';

import styles from './MultiLeadCheckboxes.module.css';

export const MultiLeadCheckboxesSkeleton = () => {
  return (
    <div className={styles.skltn}>
      <SkeletonLoader height={'86'} />
    </div>
  );
};

MultiLeadCheckboxesSkeleton.displayName = 'MultiLeadCheckboxesSkeleton';
