import { ExperimentViewedEvent } from '@tracking/Schemas/ExperimentViewed/ExperimentViewedEvent';
import { experimentViewedToEvent } from '@tracking/Schemas/ExperimentViewed/ExperimentViewedToEvent';
import { AllExperiments } from '@growthBookExperimentation/Entities/Experiments.entities';
import { FlagKey } from '@growthBookExperimentation/Entities/FlagKeys.entities';

export const getExperimentViewedEvents = (
  ids: FlagKey[],
  experiments: AllExperiments | undefined,
): ExperimentViewedEvent[] => {
  return ids.reduce<ExperimentViewedEvent[]>((events, id) => {
    const experiment = experiments?.find(exp => exp?.experimentId === id);
    if (experiment) {
      events.push(
        experimentViewedToEvent({
          experimentId: id,
          variationId: experiment?.variationId,
        }),
      );
    }
    return events;
  }, []);
};
