import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

type ReporstData = {
  [registrationNumber: string]: {
    email: string | null;
  };
};
export const reportsRequestedAtom = atomWithStorage<ReporstData>('reports', {});

export const addToReportsRequestedAtom = atom(null, (_, set, reg: ReporstData) => {
  set(reportsRequestedAtom, currentReportsRequested => ({
    ...currentReportsRequested,
    ...reg,
  }));
});

export const updateReportsRequestedAtom = atom(null, (_, set, reg, email: string | null) => {
  set(reportsRequestedAtom, (prev: ReporstData) => ({
    ...prev,
    [`${reg}`]: {
      ...prev[`${reg}`],
      email: email,
    },
  }));
});

export const removeRegFromReportsRequestedAtom = atom(null, (_, set, reg: string) => {
  set(reportsRequestedAtom, (prev: ReporstData) => {
    const newReports = { ...prev };
    delete newReports[reg];
    return newReports;
  });
});
