import { currentVehicleAtom } from '@core/Atoms/Vehicle/CurrentVehicle.atom';
import { finCalcInteractedWithAtom } from '@fincalc/Atoms/FinCalcInteractedWith.atom';

import { Typography } from '@gds/Typography/Typography';
import cn from 'classnames';
import { useAtomValue, useSetAtom } from 'jotai';

import { leadFormIdAtom } from 'Atoms/App/Pdp/LeadFormId.atom';
import { shouldShowFinanceQuoteAtom } from 'Atoms/App/Pdp/shouldShowFinanceQuote.atom';

import { BookAppointment } from './BookAppointment/BookAppointment';
import { EnrichmentProps } from './Enrichment.entity';
import { FinanceQuote } from './FinanceQuote/FinanceQuote';
import { PartExchange } from './PartExchange/PartExchange';

import styles from '../LeadForm/LeadForm.module.css';

export const Enrichment = ({
  strings,
  fieldItems,
  inputProps,
  locale,
  plp,
  flags,
}: EnrichmentProps) => {
  const leadId = useAtomValue(leadFormIdAtom);
  const vehicle = useAtomValue(currentVehicleAtom);
  const isFinCalcInteractedWith = useAtomValue(finCalcInteractedWithAtom);
  const shouldShowFinanceCalculator =
    locale === 'fr' ? !!vehicle?.pricing?.hasFinance : isFinCalcInteractedWith;
  const setShouldShowFinanceQuote = useSetAtom(shouldShowFinanceQuoteAtom);
  setShouldShowFinanceQuote(false);
  const { values, touched, errors, handleChange, handleBlur } = inputProps;

  const shouldShowCheckboxToggle = flags?.ukEnrichmentLeadformExperiment;

  const enrichmentContentClassnames = cn(styles.enrichmentContentWrapper, {
    [styles.enrichmentBorder]: shouldShowCheckboxToggle,
  });

  // fetch custom fields from configuration
  const bookAppointmentFields = fieldItems.filter(
    field => field.id === 'date' || field.id === 'preferredTime',
  );
  const partExchangeFields = fieldItems.filter(
    field =>
      field.id === 'valuationChkBox' || field.id === 'registration' || field.id === 'mileage',
  );
  const financeQuoteFields = fieldItems.filter(
    field => field.id === 'financeQuoteChkBox' || field.id === 'financeQuoteTcsChkBox',
  );
  const isFinancingVehicle = vehicle?.pricing?.hasFinance && !vehicle?.ecom.isEcom;
  const isEnrichment =
    bookAppointmentFields.length === 0 &&
    partExchangeFields.length === 0 &&
    financeQuoteFields.length === 0 &&
    !isFinancingVehicle;
  const shouldShowBookApptmnt =
    leadId !== 'book-appointment' &&
    bookAppointmentFields.length > 0 &&
    !plp &&
    !flags.kaisaCallback;
  const shouldShowFinanceQuote =
    (shouldShowFinanceCalculator && financeQuoteFields.length > 0) || isFinancingVehicle;
  setShouldShowFinanceQuote(!!shouldShowFinanceQuote);

  if (isEnrichment || leadId === 'history-check') return null;

  const financeQuoteProps = {
    strings,
    locale,
    inputProps: {
      ids: financeQuoteFields.map(field => field.id),
      handleChange,
      values: {
        isFinanceQuoteChecked: values.financeQuoteChkBox as boolean,
        isFinanceQuoteTcsChecked: values.financeQuoteTcsChkBox as boolean,
      },
    },
    shouldShowCheckboxToggle,
    id: 'finance-quote',
  };

  const partExchangeProps = {
    strings,
    inputProps: {
      ids: partExchangeFields.map(field => field.id),
      handleChange,
      handleBlur,
      touched,
      errors,
      values: {
        isSendMyValuationChecked: values.valuationChkBox as boolean,
        registration: values.registration,
        mileage: values.mileage,
      },
    },
    shouldShowCheckboxToggle,
    id: 'part-exchange',
  };

  const bookAppointmentProps = {
    dealerName: vehicle?.dealer.displayName || vehicle?.dealer.name,
    strings,
    inputProps: {
      ids: bookAppointmentFields.map(field => field.id),
      touched,
      errors,
      handleChange,
      handleBlur,
      values: {
        date: values.date!,
        preferredTime: values.preferredTime!,
      },
    },
    shouldShowCheckboxToggle,
    id: 'book-appointment',
  };

  return (
    <div className={styles.enrichmentWrapper}>
      {bookAppointmentFields.length > 0 && partExchangeFields.length > 0 && (
        <Typography variant="h6" className={styles.dealerFormHeader}>
          {strings.saveTimeLaterHeader}

          <span> {strings.oneClickOptionalLabel}</span>
        </Typography>
      )}
      <div className={enrichmentContentClassnames}>
        {shouldShowBookApptmnt && <BookAppointment {...bookAppointmentProps} />}
        {partExchangeFields.length > 0 && <PartExchange {...partExchangeProps} />}
        {shouldShowFinanceQuote && <FinanceQuote {...financeQuoteProps} />}
      </div>
    </div>
  );
};
