import { CheckoutStartEvent } from './CheckoutStartEvent';
import { checkoutStartSchema } from './CheckoutStartSchema';

type Props = {
  listingId: string | null;
  leadType?: string;
  ctaLocation: string;
};

export const checkoutStartToEvent = ({
  listingId,
  leadType,
  ctaLocation,
}: Props): CheckoutStartEvent => {
  const event: CheckoutStartEvent = {
    schema: checkoutStartSchema.ref,
    data: {
      lead_type: leadType,
      listing_id: listingId,
      cta_location: ctaLocation,
    },
  };

  return event;
};
