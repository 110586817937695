import Checkbox from '@gds/Checkbox/Checkbox';
import { Typography } from '@gds/Typography/Typography';

import styles from './ReservationCheckbox.module.css';

interface ReservationCheckoutCtaProps {
  strings: Record<string, string>;
  isReserveVehicleChecked: boolean;
  inputProps: {
    id: string;
    handleChange: (field: string, value: boolean) => void;
  };
}

export const ReservationCheckbox = ({
  strings,
  isReserveVehicleChecked,
  inputProps,
}: ReservationCheckoutCtaProps) => {
  const { id: reserveVehicleChkBoxId, handleChange } = inputProps;

  return (
    <div className={styles.container}>
      <Typography variant="h6" className={styles.title}>
        {strings.reservationCheckboxTitle}
      </Typography>
      <div className={styles.content}>
        <Checkbox
          id="one-click-similarcars"
          checked={isReserveVehicleChecked}
          onChange={() => null}
          onClick={() => handleChange(reserveVehicleChkBoxId, !isReserveVehicleChecked)}
        />
        <Typography variant="subheading4" className={styles.subtitle}>
          {strings.reservationCheckboxSubtitle}
        </Typography>
      </div>
    </div>
  );
};
