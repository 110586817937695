import { getClientSideCookieValue } from '@cookies/GetClientSideCookieValue';
import { LOCALE } from '@core/Constants/Locale.constants';
import { Locale } from '@core/Entities/Locale/Locale.entity';
import Checkbox from '@gds/Checkbox/Checkbox';
import { Info } from '@gds/Icons/Paths/Info';
import Tooltip from '@gds/Tooltip/Tooltip';
import { Typography } from '@gds/Typography/Typography';
import { flagsAtom } from '@growthBookExperimentation/Atoms/Flags.atom';
import { useAtomValue } from 'jotai';

import { CheckboxProps } from '../MultiLeadCheckboxes/MultiLeadCheckboxes.entity';
import { getDealerNames } from '../Utils/GetDealerNames';

import styles from './OneClickCheckbox.module.css';

export const ONECLICK_TERMS_LINK = {
  uk: '/uk/multi-submit-terms',
  fr: '/fr/multi-submit-terms', // will change later
  // change de later
  de: '/de/multi-submit-terms',
};

export const OneClickCheckbox = ({
  strings,
  inputProps,
  isChecked,
  similarVehicles,
}: CheckboxProps) => {
  const { id: oneClickChkBoxId, handleChange } = inputProps;
  const locale: Locale = getClientSideCookieValue(LOCALE);
  const { leadFormOneClickChkbox: hasOneClick } = useAtomValue(flagsAtom);
  const { oneClickCheckbox, infoPopoverSimilarCars, oneClickTerms } = strings;

  const isMultiLead = hasOneClick && !!similarVehicles?.length;

  if (!isMultiLead) return null;
  const dealers = getDealerNames(similarVehicles);

  // temporary solution for dynamic phrase string
  const infoPopOverText = infoPopoverSimilarCars.replace('{dealers}', dealers);

  return (
    <label
      htmlFor="one-click-similarcars"
      aria-labelledby="one-click-similarcars"
      className={styles.oneClickCheckbox}
    >
      <Checkbox
        id="one-click-similarcars"
        checked={isChecked}
        onChange={() => null}
        onClick={() => handleChange(oneClickChkBoxId, !isChecked)}
      />
      <Typography variant="subheading4" Component="span">
        {oneClickCheckbox}{' '}
        <Typography
          variant="buttonLink1"
          Component="a"
          className={styles.termHref}
          // @ts-expect-error - href
          href={ONECLICK_TERMS_LINK[locale]}
          target="_blank"
        >
          {oneClickTerms}
        </Typography>
        <Tooltip className={styles.tooltip} dialogId="oneclk-chkbk-popover" text={infoPopOverText}>
          <Info fontSize={24} />
        </Tooltip>
      </Typography>
    </label>
  );
};
