import { LeadFormState } from '@core/Atoms/Pdp/FormState.atom';
import { SearchParamsType } from '@core/Atoms/SearchParams/SearchParams.atom';
import { TrackingDataType } from '@core/Entities/Tracking/Tracking.entity';
import { Vehicle } from '@core/Entities/Vehicle/Vehicle.entity';
import { AllExperiments } from '@growthBookExperimentation/Entities/Experiments.entities';
import { checkoutStartToEvent } from '@tracking/Schemas/Checkout/CheckoutStart/CheckoutStartToEvent';
import { vehicleToListingEvent } from '@tracking/Schemas/Listing/VehicleToListingEvent';
import { vehicleToListingFeatureEvent } from '@tracking/Schemas/ListingFeature/VehicleToListingFeatureEvent';
import { marketingToEvent } from '@tracking/Schemas/Marketing/MarketingToEvent';
import { userContextToEvent } from '@tracking/Schemas/UserContext/UserContextToEvent';

interface Props {
  searchParams: SearchParamsType;
  userId: string | null | undefined;
  formState: LeadFormState;
  listingId: string | null;
  leadFormId?: string | undefined;
  source?: string;
  vehicle: Vehicle;
  ctaLocation?: string;
  experiments?: AllExperiments;
}

export function generateCheckoutStartTrackingData({
  searchParams,
  userId,
  formState,
  listingId,
  leadFormId,
  vehicle,
  ctaLocation,
  experiments,
}: Props): Record<string, TrackingDataType> {
  const marketingDataObj = {
    marketingValues: {
      ...searchParams,
    },
  };

  const userData = {
    userId,
    values: { ...formState },
  };
  const leadStartObj = {
    listingId,
    leadType: leadFormId as string,
    ctaLocation,
  };

  // event actions
  const checkoutStartEvent = checkoutStartToEvent(leadStartObj);

  // event entities
  const userEvent = userContextToEvent(userData);
  const listingEvent = vehicleToListingEvent(vehicle);
  const marketingEvent = marketingToEvent(marketingDataObj);
  const listingFeatureEvent = vehicleToListingFeatureEvent(vehicle);

  const contextStart = [listingEvent, listingFeatureEvent, userEvent, marketingEvent];

  return {
    checkoutStart: { event: checkoutStartEvent, context: contextStart },
  };
}
