export const mapSelectedProductToLeadSubtype = (selectedProduct?: string): string => {
  const lowerCaseProduct = selectedProduct?.toLowerCase();
  if (
    lowerCaseProduct === 'hp' ||
    lowerCaseProduct === 'pcp' ||
    lowerCaseProduct === 'standardfinancing'
  ) {
    return 'finance';
  } else if (lowerCaseProduct === 'privateleasing') {
    return 'leasing';
  }
  return lowerCaseProduct || 'default';
};
