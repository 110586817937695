import { ErrorMessages } from 'Components/FormFields/FormFields.entity';

export const defaultErrorMessage = ({ messages }: { messages: ErrorMessages }) => {
  return messages.invalidErrorMessage;
};

export const fullNameErrorMessage = ({ messages }: { messages: ErrorMessages }) => {
  return messages.invalidFullNameErrorMessage;
};

export const dealerClosedErrorMessage = ({ messages }: { messages: ErrorMessages }) => {
  return messages.dealerClosedErrorMessage;
};

export const dateTooFarErrorMessage = ({ messages }: { messages: ErrorMessages }) => {
  return messages.dateTooFarErrorMessage;
};
