import { useEffect } from 'react';

import { userProfileAtom } from '@my-account/Atoms/UserProfile.atom';
import { useAtomValue } from 'jotai';

export const usePrePopulateLeadForm = ({ handleChange }) => {
  const { data: userProfile } = useAtomValue(userProfileAtom);

  useEffect(() => {
    if (userProfile) {
      const { firstName, lastName, email, phoneNumber } = userProfile;

      if (firstName) handleChange('firstName', firstName);
      if (lastName) handleChange('lastName', lastName);
      if (email) handleChange('email', email);
      if (phoneNumber) handleChange('phone', phoneNumber);
    }
  }, [userProfile]);
};
