import { Locale } from '@core/Entities/Locale/Locale.entity';
import { VehicleDealerOpeningTime } from '@core/Entities/Vehicle/VehicleDealerOpeningTime.entity';
import { bufferOpeningTimesByMinutes } from '@core/Utils/OpeningTimes/BufferOpeningTimesByMinutes';
import { isBusinessHours } from '@core/Utils/isBusinessHours/isBusinessHours';

export const isInstantCallbackEnabled = <
  Flags extends {
    messageLeadAutomaticCallbackTest: boolean;
  },
>({
  flags,
  dealerOpeningTimes,
  locale,
}: {
  flags: Flags;
  dealerOpeningTimes: VehicleDealerOpeningTime[];
  locale?: Locale;
}) => {
  const isFlagOn = flags.messageLeadAutomaticCallbackTest;
  const bufferedOpeningTimes = dealerOpeningTimes.map(bufferOpeningTimesByMinutes(10));
  const isWithinBufferedOpeningTimes = isBusinessHours(bufferedOpeningTimes);
  const isFrance = locale === 'fr';

  return isFlagOn && isWithinBufferedOpeningTimes && isFrance;
};
