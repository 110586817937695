import { atom } from 'jotai';

import { StringsType } from 'Components/LeadForms/LeadForm/LeadForm.entity';
import { defaultLeadformMessages } from 'Components/LeadForms/Leadforms.messages';
import { defaultPdpMessages } from 'Components/Pdp/Pdp.messages';

export const leadFormStringsAtom = atom<StringsType>({
  ...defaultLeadformMessages,
  ...defaultPdpMessages,
});
