import { experimentViewedSchema } from './ExperimentViewedSchema';
import { ExperimentViewedEvent } from './ExperimentViewedEvent';

export const experimentViewedToEvent = ({
  experimentId,
  variationId,
}: {
  experimentId: string;
  variationId: string;
}): ExperimentViewedEvent => {
  const event: ExperimentViewedEvent = {
    schema: experimentViewedSchema.ref,
    data: {
      experiment_id: experimentId,
      variation_id: variationId,
    },
  };

  return event;
};
