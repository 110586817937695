import { TrackingEvent } from '../../../Entities/Schema.entity';
import { hovStartSchema } from './HovStartSchema';

export const hovStartToEvent = (): TrackingEvent<{}> => {
  const event: TrackingEvent<{}> = {
    schema: hovStartSchema.ref,
    data: {},
  };

  return event;
};
