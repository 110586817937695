import { CallSubmitEvent } from './CallSubmitEvent';
import { callSubmitSchema } from './CallSubmitSchema';

type Props = {
  leadType: string;
  listingId: string;
  leadId: string;
  callNumber: string;
  ctaLocation: string;
};

export const callSubmitToEvent = ({
  leadType,
  leadId,
  listingId,
  callNumber,
  ctaLocation,
}: Props): CallSubmitEvent => {
  const event: CallSubmitEvent = {
    schema: callSubmitSchema.ref,
    data: {
      lead_type: leadType,
      lead_id: leadId,
      listing_id: listingId,
      call_number: callNumber,
      cta_location: ctaLocation,
    },
  };

  return event;
};
