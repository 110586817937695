export const heycarLoader = ({
  src,
  width,
  quality,
}: {
  src: string;
  width: number;
  quality?: number;
}) => {
  const baseUrl =
    process.env.NEXT_PUBLIC_COMMON_API_URL || 'https://api.common.staging.group-mobility-trader.de';
  const baseImgUrl = baseUrl.replace('api', 'img');
  const height = Math.ceil(width * 0.75);

  const preString = `${baseImgUrl}/unsafe/fit-in/${width}x${height}/filters:quality(${
    quality || '60'
  }):no_upscale():fill(blur)/`;

  return preString + src;
};
