import { localeAtom } from '@core/Atoms/Locale/Locale.atom';

import Button from '@gds/Button/Button';
import { useAtom, useSetAtom } from 'jotai';

import { openMessageDealerModalAtom } from 'Atoms/App/Pdp/OpenMessageDealerModal.atom';

export const SendDealerMessageAction = ({ label }: { label: string }) => {
  const setOpenMessageModal = useSetAtom(openMessageDealerModalAtom);
  const [locale] = useAtom(localeAtom);

  const handleBtnClick = () => {
    setOpenMessageModal(locale);
  };
  return (
    <div>
      <Button onClick={handleBtnClick}>{label}</Button>
    </div>
  );
};
