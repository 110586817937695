import { InputItem } from 'Components/FormFields/FormFields.entity';

export const getMultiLeadCheckboxFields = (formFields: InputItem[]) => {
  const oneClickCheckboxFields = formFields.find(({ id }) => id === 'oneClickChkBox');
  const oneClickDriftrockCheckboxFields = formFields.find(
    ({ id }) => id === 'oneClickDriftrockChkBox',
  );
  const driftrockCheckboxFields = formFields.find(({ id }) => id === 'driftrockChkBox');

  return {
    oneClickCheckboxFields,
    oneClickDriftrockCheckboxFields,
    driftrockCheckboxFields,
    hasMultileadOption:
      !!oneClickCheckboxFields || !!oneClickDriftrockCheckboxFields || !!driftrockCheckboxFields,
  };
};
