import { FC } from 'react';

import Button from '@gds/Button/Button';
import { CallBack } from '@gds/Icons/Paths/CallBack';

import { StringsType } from '../LeadForm/LeadForm.entity';

import styles from './CallBackModal.module.css';

export const CallBackModal: FC<{
  handleCallbackClick: () => void;
  messages: StringsType;
}> = ({ handleCallbackClick, messages }) => {
  return (
    <>
      <div className={styles.callBackModalWrapper}>
        <div className={styles.error}>{messages.callDealerError}</div>
        <Button
          leftIcon={<CallBack />}
          variant={'outlined'}
          onClick={handleCallbackClick}
          className={styles.cta}
          loading={false}
        >
          {messages.requestCallbackButton}
        </Button>
      </div>
    </>
  );
};
