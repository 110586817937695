import { localeAtom } from '@core/Atoms/Locale/Locale.atom';
import { closeModalAtom } from '@core/Atoms/Modal/Modal.atom';
import Button from '@gds/Button/Button';
import { useAtomValue, useSetAtom } from 'jotai';

import { CallDealerCTA } from '../CallDealer/CallDealerCTA';
import { StringsType } from '../LeadForm/LeadForm.entity';

import styles from './HistoryCheck.module.css';

export const HistoryCheckReturnAction = ({
  strings,
}: {
  returnToPage?: boolean;
  strings: StringsType;
}) => {
  const closeModalAtoms = useSetAtom(closeModalAtom);
  const locale = useAtomValue(localeAtom);

  const handleBtnClick = () => {
    if (window.innerWidth < 1024) {
      window.scroll({
        top: 0,
        behavior: 'smooth',
      });
    }
    closeModalAtoms();
  };

  return (
    <div className={styles.ctasWrapper}>
      <Button variant="outlined" onClick={handleBtnClick}>
        {strings.successHistoryCheckModalBackButton}
      </Button>
      <CallDealerCTA
        ctaPlacement="hov_call-dealer"
        strings={strings}
        locale={locale}
        isEcom={false}
        isSticky={false}
      />
    </div>
  );
};
