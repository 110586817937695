import { useState, FC } from 'react';

import Checkbox from '@gds/Checkbox/Checkbox';
import { Typography } from '@gds/Typography/Typography';
import cn from 'classnames';

import { AccordionCheckboxProps } from './AccordionCheckbox.entity';

import styles from './AccordionCheckbox.module.css';

export const AccordionCheckbox: FC<AccordionCheckboxProps> = ({ title, children, id }) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const classNames = cn(styles.accordionCheckbox, {
    [styles.accordionOpen]: isVisible,
  });

  const handleToggle = () => {
    setIsVisible(prev => !prev);
  };

  return (
    <div className={classNames}>
      <label htmlFor="accordion-toggle" className={styles.checkboxLabel}>
        <Checkbox
          id={`${id}-accordion-toggle`}
          checked={isVisible}
          onChange={handleToggle}
          className={styles.checkbox}
        />
        <Typography variant="body3" Component="span">
          {title}
        </Typography>
      </label>
      {isVisible && <div className={styles.checkboxContent}>{children}</div>}
    </div>
  );
};
