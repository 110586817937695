import { EMAIL_VALIDATION } from '@core/Constants/Validations';

import { InputItem } from 'Components/FormFields/FormFields.entity';

import { defaultErrorMessage } from '../Utils/ErrorMessages';
import { isValidPhoneNumber } from '../Utils/Leadforms.util';

const LeasingOnlineModalFieldItems: InputItem[] = [
  {
    id: 'firstName',
    dataTestId: 'firstName',
    type: 'input',
    autoComplete: 'on',
    label: ({ firstNameLabel }) => firstNameLabel,
    isValid: ({ value }) => value?.length > 2,
    isActive: () => true,
    width: () => 'half',
    errorMessage: defaultErrorMessage,
  },
  {
    id: 'lastName',
    dataTestId: 'lastName',
    type: 'input',
    autoComplete: 'on',
    label: ({ lastNameLabel }) => lastNameLabel,
    isValid: ({ value }) => value?.length > 2,
    isActive: () => true,
    width: () => 'half',
    errorMessage: defaultErrorMessage,
  },
  {
    id: 'email',
    dataTestId: 'email',
    type: 'input',
    label: ({ emailAddressLabel }) => emailAddressLabel,
    isValid: ({ value }) => EMAIL_VALIDATION.test(value),
    isActive: () => true,
    width: () => 'half',
    errorMessage: defaultErrorMessage,
  },
  {
    id: 'phone',
    dataTestId: 'phone',
    type: 'input',
    label: ({ phoneNumberLabel }) => phoneNumberLabel,
    isValid: ({ value }) => isValidPhoneNumber(value),
    isActive: () => true,
    width: () => 'half',
    errorMessage: defaultErrorMessage,
  },

  {
    id: 'marketingOptIns',
    dataTestId: 'mktOptinOpttions',
    defaultValue: () => false,
    isActive: () => true,
  },
];

export { LeasingOnlineModalFieldItems };
