import { VehicleDealerOpeningTime } from '@core/Entities/Vehicle/VehicleDealerOpeningTime.entity';

export function isBusinessHours(openingTimes?: VehicleDealerOpeningTime[]): boolean {
  if (!openingTimes || openingTimes.length === 0) {
    return false;
  }

  const currentTime = new Date();
  const currentDay = currentTime.getDay() === 0 ? 7 : currentTime.getDay(); // 7 = Sunday in dayOfWeek from openingTimes
  const currentHour = currentTime.getHours();
  const currentMinutes = currentTime.getMinutes();

  const todayOpeningTimes: VehicleDealerOpeningTime[] = openingTimes.filter(
    time => time.dayOfWeek === currentDay,
  );

  if (todayOpeningTimes.length === 0) {
    return false;
  }

  for (const time of todayOpeningTimes) {
    const [fromHour, fromMinute] = time.openFrom.split(':').map(Number);
    const [toHour, toMinute] = time.openTo.split(':').map(Number);

    if (
      (currentHour > fromHour || (currentHour === fromHour && currentMinutes >= fromMinute)) &&
      (currentHour < toHour || (currentHour === toHour && currentMinutes < toMinute))
    ) {
      return true;
    }
  }

  return false;
}
