import { getClientSideCookieValue } from '@cookies/GetClientSideCookieValue';
import { Locale } from '@core/Entities/Locale/Locale.entity';
import { atomWithStorage } from 'jotai/utils';

import { optInKeys } from 'Actions/OptIn/OptIn.constants';
import { OptInData } from 'Actions/OptIn/OptIn.entity';

const locale = getClientSideCookieValue<Locale>('locale') ?? 'uk';
const optInAtom = atomWithStorage<OptInData>(optInKeys[locale] ?? optInKeys.uk, {});

export { optInAtom };
