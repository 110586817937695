import { localeAtom } from '@core/Atoms/Locale/Locale.atom';
import { postcodeAtom } from '@core/Atoms/Postcode/Postcode.atom';
import { currentVehicleAtom } from '@core/Atoms/Vehicle/CurrentVehicle.atom';
import { Vehicle } from '@core/Entities/Vehicle/Vehicle.entity';
import { atom } from 'jotai';
import { unwrap } from 'jotai/utils';

import { fetchSimilarVehiclesOnClient } from 'Components/LeadForms/Utils/FetchSimilarVehiclesOnClient';

export const asyncSimilarVehiclesAtom = atom(async get => {
  const vehicle = get(currentVehicleAtom);
  const locale = get(localeAtom);
  const postcode = get(postcodeAtom);

  if (!vehicle) return null;

  const response = await fetchSimilarVehiclesOnClient(vehicle.heycarId, postcode[locale], locale);

  if (!response || !response?.content) return null;

  const vehicles: Vehicle[] = response?.content;

  return vehicles;
});

export const similarVehiclesAtom = unwrap(asyncSimilarVehiclesAtom);
