import { getClientSideCookieValue } from '@cookies/GetClientSideCookieValue';
import { LOCALE } from '@core/Constants/Locale.constants';
import { calculationAtom } from '@fincalc/Atoms/Calculation.atom';
import Button from '@gds/Button/Button';
import { Typography } from '@gds/Typography/Typography';
import { useAtomValue } from 'jotai';

import { PRIVACY_POLICY_LINK, VWFS_PRIVACY_POLICY_LINK } from './LeadsFormFooter.constants';

import styles from './LeadsFormFooter.module.css';

interface LeadsModalFooterProps {
  strings: Record<string, string>;
  isFormFilled: boolean;
}

export const LeadsFormFooter = ({
  strings: { privacyPolicy, footerButton, privacyPolicyCaption, vwfsPrivacyPolicy, andVwfs },
  isFormFilled,
}: LeadsModalFooterProps) => {
  const locale = getClientSideCookieValue(LOCALE);
  const calculation = useAtomValue(calculationAtom)!;
  const isVwfs = calculation?.captiveId === 'vwfs_fr';

  return (
    <div className={styles.leadsModalfooter}>
      <Typography Component={'p'} variant="caption2" className="privacyPolicy">
        {privacyPolicyCaption}{' '}
        <Typography
          variant="buttonLink3"
          Component="a"
          // @ts-expect-error - href
          href={PRIVACY_POLICY_LINK[locale]}
          target="_blank"
        >
          {privacyPolicy}
        </Typography>
        {isVwfs && (
          <span>
            {' '}
            {andVwfs}{' '}
            <Typography
              variant="buttonLink3"
              Component="a"
              // @ts-expect-error - href
              href={VWFS_PRIVACY_POLICY_LINK.vwfsPrivacy}
              target="_blank"
            >
              {vwfsPrivacyPolicy}
            </Typography>
          </span>
        )}
      </Typography>

      <Button type="submit" data-test-id="footerSubmit" disabled={!isFormFilled}>
        {footerButton}
      </Button>
    </div>
  );
};
