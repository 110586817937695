import { VehicleDealerOpeningTime } from '@core/Entities/Vehicle/VehicleDealerOpeningTime.entity';

export const getOpeningHours = (
  selectedDate?: string,
  openingTimes?: VehicleDealerOpeningTime[],
) => {
  if (!selectedDate || !openingTimes) {
    return [];
  }

  const date = new Date(selectedDate);
  const today = new Date();
  const isToday = date.toDateString() === today.toDateString();

  const dayOfWeek = date.getDay(); // Sunday - Saturday : 0 - 6
  const normalizedDayOfWeek = ((dayOfWeek + 6) % 7) + 1; // Monday - Sunday : 1 - 7

  const openingTimesForDay = openingTimes.filter(time => time.dayOfWeek === normalizedDayOfWeek);
  if (!openingTimesForDay.length) {
    return [];
  }

  const timeSlots = openingTimesForDay.flatMap(openingTime => {
    const openFrom = parseTime(openingTime.openFrom);
    const openTo = parseTime(openingTime.openTo);
    return generateTimeSlots(openFrom, openTo);
  });

  const filteredSlots = isToday
    ? timeSlots.filter(slot => {
        const nextHour = today.getHours() + 1;
        return slot.start.hours >= nextHour;
      })
    : timeSlots;

  return filteredSlots.map(slot => ({
    value: `${formatTime(slot.start)} - ${formatTime(slot.end)}`,
    label: `${formatTime(slot.start)} - ${formatTime(slot.end)}`,
  }));
};

const parseTime = (time: string) => {
  const [hours, minutes] = time.split(':').map(Number);
  return { hours, minutes };
};

const formatTime = (time: { hours: number; minutes: number }) => {
  const hours = time.hours.toString().padStart(2, '0');
  const minutes = time.minutes.toString().padStart(2, '0');
  return `${hours}:${minutes}`;
};

const generateTimeSlots = (
  start: { hours: number; minutes: number },
  end: { hours: number; minutes: number },
) => {
  const slots = [];
  let current = { ...start };

  // Adjust the first slot to the next round hour if start time is not on the hour
  if (current.minutes !== 0) {
    const next = {
      hours: current.hours,
      minutes: 60,
    };

    if (next.minutes >= 60) {
      next.hours += 1;
      next.minutes = 0;
    }

    slots.push({ start: { ...current }, end: { ...next } });
    current = { ...next };
  }

  while (
    current.hours < end.hours ||
    (current.hours === end.hours && current.minutes < end.minutes)
  ) {
    let next = { hours: current.hours + 1, minutes: 0 };

    if (next.hours > end.hours || (next.hours === end.hours && next.minutes > end.minutes)) {
      next = { ...end };
    }

    slots.push({ start: { ...current }, end: { ...next } });
    current = { ...next };
  }

  return slots;
};
