import { Typography } from '@gds/Typography/Typography';

import styles from './ReCaptchaPolicy.module.css';
export const ReCaptchaPolicy = ({ strings }: any) => {
  return (
    <Typography variant="caption2" Component="span" className={styles.recaptcha}>
      {strings.leadsReCaptcha}
    </Typography>
  );
};
