import { CallStartEvent } from './CallStartEvent';
import { callStartSchema } from './CallStartSchema';

type Props = {
  leadType: string;
  listingId: string | null;
  ctaLocation: string;
};

export const callStartToEvent = ({ leadType, listingId, ctaLocation }: Props): CallStartEvent => {
  const event: CallStartEvent = {
    schema: callStartSchema.ref,
    data: {
      lead_type: leadType,
      listing_id: listingId,
      cta_location: ctaLocation,
    },
  };

  return event;
};
