import {
  EMAIL_VALIDATION,
  FR_NUMBER_PLATE_REGEX,
  NUMBER_PLATE_REGEX,
  UK_POSTCODE_REGEX,
} from '@core/Constants/Validations';
import { validateFullName } from '@core/Utils/Validation/ValidateFullName';

import { ErrorMessages, InputItem } from 'Components/FormFields/FormFields.entity';

import { TITLE_OPTIONS } from '../../../Constants/LeadForm.constants';

import {
  dateTooFarErrorMessage,
  dealerClosedErrorMessage,
  defaultErrorMessage,
  fullNameErrorMessage,
} from '../Utils/ErrorMessages';
import { getOpeningHours } from '../Utils/GetOpeningHours';
import {
  checkMinDate,
  checkDealerOpenOnDate,
  isValidPhoneNumber,
  checkMaxDate,
} from '../Utils/Leadforms.util';

export const RequestCallbackFieldItems: InputItem[] = [
  {
    id: 'title',
    type: 'select',
    label: ({ titleLabel }) => titleLabel,
    width: () => 'double',
    options: () => TITLE_OPTIONS,
    isActive: ({ locale, flags, vehicle }) =>
      locale === 'fr' && flags.cgiEligible && vehicle.dealer.fdlConditions === 'cgi_fr',
    isValid: ({ value }) => !!value,
    errorMessage: defaultErrorMessage,
  },
  {
    id: 'firstName',
    dataTestId: 'text',
    type: 'input',
    autoComplete: 'on',
    label: ({ firstNameLabel }) => firstNameLabel,
    isValid: ({ value }) => value?.length > 2,
    isActive: ({ flags }) => !flags.nameFieldLeadformExperiment,
    width: () => 'half',
    errorMessage: defaultErrorMessage,
  },
  {
    id: 'lastName',
    dataTestId: 'text',
    type: 'input',
    autoComplete: 'on',
    label: ({ lastNameLabel }) => lastNameLabel,
    isValid: ({ value }) => value?.length > 2,
    isActive: ({ flags }) => !flags.nameFieldLeadformExperiment,
    width: () => 'half',
    errorMessage: defaultErrorMessage,
  },
  {
    id: 'fullName',
    dataTestId: 'fullName',
    type: 'input',
    autoComplete: 'on',
    label: ({ fullNameLabel }) => fullNameLabel,
    isValid: ({ value }) => validateFullName(value),
    isActive: ({ flags }) => flags.nameFieldLeadformExperiment,
    width: () => 'full',
    errorMessage: fullNameErrorMessage,
  },
  {
    id: 'email',
    dataTestId: 'email',
    type: 'input',
    label: ({ emailAddressLabel }) => emailAddressLabel,
    isValid: ({ value }) => EMAIL_VALIDATION.test(value),
    isActive: ({ flags }) => flags.kaisaCallback,
    width: ({ flags }) => (flags.postcodeRequired ? 'full' : 'half'),
    errorMessage: defaultErrorMessage,
  },
  {
    id: 'phone',
    dataTestId: 'text',
    type: 'input',
    label: ({ phoneNumberLabel }) => phoneNumberLabel,
    isValid: ({ value }) => isValidPhoneNumber(value.trim()),
    isActive: () => true,
    width: () => 'half',
    errorMessage: defaultErrorMessage,
  },
  {
    id: 'postcode',
    dataTestId: 'text',
    type: 'input',
    label: ({ postalCodeLabel }) => postalCodeLabel,
    isValid: ({ value }) => UK_POSTCODE_REGEX.test(value.trim()),
    isActive: ({ flags }) => !!flags.postcodeRequired,
    width: () => 'half',
    errorMessage: defaultErrorMessage,
  },
  {
    type: 'date',
    id: 'date',
    label: ({ bookAppointmentDate }) => bookAppointmentDate,
    isValid: ({ value, vehicle }) =>
      checkMinDate(value) && checkMaxDate(value) && checkDealerOpenOnDate(value, vehicle),
    errorMessage: ({ messages, value }: { messages: ErrorMessages; value: string }) => {
      return !value
        ? defaultErrorMessage({ messages })
        : !checkMaxDate(value)
        ? dateTooFarErrorMessage({ messages })
        : checkMinDate(value)
        ? dealerClosedErrorMessage({ messages })
        : defaultErrorMessage({ messages });
    },
    isActive: () => true,
  },
  {
    type: 'select',
    id: 'preferredTime',
    label: ({ bookAppointmentPreferredTime }) => bookAppointmentPreferredTime,
    isValid: ({ value }) => !!value,
    options: strings => [
      { value: 'morning', label: strings?.bookAppointmentMorning },
      { value: 'afternoon', label: strings?.bookAppointmentAfternoon },
    ],
    errorMessage: defaultErrorMessage,
    isActive: ({ flags }) => !flags.kaisaCallback,
  },
  {
    type: 'select',
    id: 'kaisaTime',
    label: ({ bookAppointmentKaisaTime }) => bookAppointmentKaisaTime,
    isActive: ({ flags, values, vehicle }) =>
      flags.kaisaCallback &&
      values['date'] &&
      checkDealerOpenOnDate(values['date'], vehicle) &&
      checkMinDate(values['date']) &&
      checkMaxDate(values['date']),
    isValid: ({ value }) => !!value,
    options: (strings, vehicle, values) =>
      getOpeningHours(values && values['date'], vehicle?.dealer.openingTimes),
    errorMessage: defaultErrorMessage,
  },
  {
    id: 'message',
    dataTestId: 'text',
    type: 'textarea',
    label: ({ optionalMessageLabel }) => optionalMessageLabel,
    width: () => 'full',
    isActive: () => true,
  },

  {
    id: 'valuationChkBox',
    defaultValue: () => true,
    isActive: ({ vehicle, flags }) =>
      flags.leadEnrichmentPartExchange &&
      !!vehicle.pricing.package?.messagePartX &&
      !!vehicle.pricing.package?.messageValuation,
  },
  {
    id: 'registration',
    errorMessage: defaultErrorMessage,
    isValid: ({ value }) =>
      // Returning true if value is empty [optional field]
      value === '' ? true : NUMBER_PLATE_REGEX.test(value) || FR_NUMBER_PLATE_REGEX.test(value),
    isActive: ({ vehicle, flags }) =>
      flags.leadEnrichmentPartExchange && !!vehicle.pricing.package?.messagePartX,
  },
  {
    id: 'mileage',
    errorMessage: defaultErrorMessage,
    isActive: ({ vehicle, flags }) =>
      flags.leadEnrichmentPartExchange && !!vehicle.pricing.package?.messagePartX,
  },
  {
    id: 'oneClickChkBox',
    defaultValue: () => false,
    isActive: ({ flags }) => flags.leadFormOneClickChkbox,
  },
  {
    id: 'financeQuoteChkBox',
    defaultValue: ({ locale }) => (locale === 'uk' ? true : false),
    isActive: ({ vehicle, flags, isFinCalcInteractedWith }) =>
      (flags.leadEnrichmentFinanceQuote && !!vehicle?.pricing.package?.messageFinance) ||
      isFinCalcInteractedWith,
  },
  {
    id: 'financeQuoteTcsChkBox',
    type: 'checkbox',
    defaultValue: ({ isFinCalcInteractedWith }) => !isFinCalcInteractedWith,
    isActive: ({ vehicle, flags, isFinCalcInteractedWith, locale }) =>
      locale === 'fr' &&
      ((flags.leadEnrichmentFinanceQuote && !!vehicle?.pricing.package?.messageFinance) ||
        isFinCalcInteractedWith),
    isValid: ({ values }) => {
      return (
        (values['financeQuoteChkBox'] && values['financeQuoteTcsChkBox']) ||
        !values['financeQuoteChkBox']
      );
    },
    errorMessage: defaultErrorMessage,
  },
];
