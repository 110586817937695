import { Accordion } from '@gds/Accordion/Accordion';
import { Dropdown } from '@gds/Dropdown/Dropdown';
import Grid from '@gds/Grid/Grid';
import { Location } from '@gds/Icons/Paths/Location';
import { Input } from '@gds/Input/Input';
import { Typography } from '@gds/Typography/Typography';

import { checkMinDate } from 'Components/LeadForms/Utils/Leadforms.util';

import { AccordionCheckbox } from '../../AccordionCheckbox/AccordionCheckbox';
import { BookAppointmentProps } from '../Enrichment.entity';

import styles from './BookAppointment.module.css';

export const BookAppointment = ({
  dealerName,
  strings,
  inputProps,
  shouldShowCheckboxToggle,
  id,
}: BookAppointmentProps) => {
  const {
    bookAppointmentAccordionHeader: accordionHeader,
    bookAppointmentCaption: caption,
    bookAppointmentDate: dateLabel,
    bookAppointmentPreferredTime: preferredTimeLabel,
    enrichmentTextHeaderClose: headerClose,
    enrichmentTextHeaderOpen: headerOpen,
    bookAppointmentMorning: morning,
    bookAppointmentAfternoon: afternoon,
  } = strings;
  const PREFERRED_TIMES_OPTIONS = [
    { value: 'morning', label: morning },
    { value: 'afternoon', label: afternoon },
  ];
  const {
    touched,
    errors,
    ids: [dateId, preferredId],
    handleChange,
    handleBlur,
    values: { date, preferredTime },
  } = inputProps;

  const customToggleTextHeader = {
    openText: headerOpen,
    closeText: headerClose,
  };
  const minDate = new Date();

  const content = (
    <Grid.Row className={styles.bookAppointmentWrapper}>
      <Grid.Col width={{ tablet: 6, mobile: 12 }}>
        <Typography variant="body3" Component="span">
          {caption}
        </Typography>
        <div className={styles.dealerName}>
          <Location />
          <Typography variant="body1">{dealerName}</Typography>
        </div>
      </Grid.Col>
      <Grid.Col width={{ tablet: 6, mobile: 12 }} className={styles.inputFieldsWrapper}>
        <Input
          label={dateLabel}
          onBlur={() => handleBlur(dateId)}
          onChange={({ target: { value } }) => handleChange(dateId, value)}
          value={date || ''}
          id={`${id}_${dateId}`}
          error={touched[dateId] && !checkMinDate(date) && errors[dateId]}
          fullWidth
          type={'date' as unknown as 'text'}
          min={minDate?.toISOString?.().split('T')[0]}
          dataTestId="date-input"
        />
        <Dropdown
          label={preferredTimeLabel}
          dataTestId="preferred-time"
          options={PREFERRED_TIMES_OPTIONS!}
          onChange={e => handleChange(preferredId, e)}
          value={preferredTime || ''}
          className={styles.dropDown}
        />
      </Grid.Col>
    </Grid.Row>
  );

  return (
    <>
      {shouldShowCheckboxToggle ? (
        <AccordionCheckbox title={accordionHeader} id={id}>
          {content}
        </AccordionCheckbox>
      ) : (
        <Accordion title={accordionHeader} customtoggletextheader={customToggleTextHeader}>
          {content}
        </Accordion>
      )}
    </>
  );
};
