import { EMAIL_VALIDATION } from '@core/Constants/Validations';

import { InputItem } from 'Components/FormFields/FormFields.entity';

import { defaultErrorMessage } from '../Utils/ErrorMessages';

const HistoryCheckItems: InputItem[] = [
  {
    id: 'email',
    dataTestId: 'historyCheckEmail',
    type: 'input',
    label: ({ emailAddressLabel }) => emailAddressLabel,
    isValid: ({ value }) => EMAIL_VALIDATION.test(value),
    isActive: () => true,
    width: () => 'full',
    errorMessage: defaultErrorMessage,
  },

  {
    id: 'marketingOptIns',
    dataTestId: 'historyCheckMktOptinOpttions',
    defaultValue: () => false,
    isActive: () => true,
  },
];

export { HistoryCheckItems };
