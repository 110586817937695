import {
  Aggregations,
  AggregationsState,
} from '../../Entities/Search/Aggregations/Aggregations.entity';
import { getModelNameFromId } from '../../Services/Search/Utils/GetModelNameFromId';
import { capitalCase } from '../Strings/CapitalCase';

export const getMakeModelLabelsFromAggregations = (
  make: string | undefined,
  model: string | undefined,
  aggregations?: Aggregations,
) => {
  const output: { make: string | undefined; model: string | undefined } = {
    make: undefined,
    model: undefined,
  };
  if (!make) return output;

  output.make = aggregations?.make?.[make]?.label || make;
  if (model) output.model = aggregations?.model?.[make]?.models[model]?.label || model;

  return output;
};

export const getMakeModelLabelsFromAggregationsArray = (
  make: string | undefined,
  model: string | undefined,
  aggregations: AggregationsState,
) => {
  const output: { make: string | undefined; model: string | undefined } = {
    make: undefined,
    model: undefined,
  };
  if (!make) return output;

  const makeObj = aggregations.makes?.find(makeData => makeData.key === make);
  const modelObj = aggregations.models?.find(modelData => modelData.key === model);

  output.make = makeObj ? makeObj.displayName : make;
  output.model = modelObj ? modelObj.displayName : capitalCase(getModelNameFromId(model || ''));

  return output;
};

export const getMakeModelIdFromAggregations = (
  make: string | undefined,
  model: string | undefined,
  aggregations?: Aggregations,
) => {
  const output: { make: string | undefined; model: string | undefined } = {
    make: undefined,
    model: undefined,
  };
  if (!make) return output;

  output.make = aggregations?.make?.[make]?.label || make;
  if (model) output.model = aggregations?.model?.[make]?.models[model]?.label || model;

  return output;
};
