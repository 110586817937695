import { TrackingEvent } from '../../../Entities/Schema.entity';
import { hovSubmitSchema } from './HovSubmitSchema';

export const hovSubmitToEvent = (): TrackingEvent<{}> => {
  const event: TrackingEvent<{}> = {
    schema: hovSubmitSchema.ref,
    data: {},
  };

  return event;
};
