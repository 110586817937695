import { LeadFormState } from '@core/Atoms/Pdp/FormState.atom';
import { SearchParamsType } from '@core/Atoms/SearchParams/SearchParams.atom';
import { TrackingDataType } from '@core/Entities/Tracking/Tracking.entity';
import { Vehicle } from '@core/Entities/Vehicle/Vehicle.entity';
import { addFavouritesToEvent } from '@tracking/Schemas/Favourites/AddFavourites/AddFavouritesToEvent';
import { GenericVehicleListingSubset } from '@tracking/Schemas/Listing/ExtractGenericVehicleListingSubset';
import { extractVehicleListingSubset } from '@tracking/Schemas/Listing/ExtractVehicleListingSubset';
import { vehicleToListingEvent } from '@tracking/Schemas/Listing/VehicleToListingEvent';
import { extractVehicleListingFeatureSubset } from '@tracking/Schemas/ListingFeature/ExtractVehicleListingFeatureSubset';
import { vehicleToListingFeatureEvent } from '@tracking/Schemas/ListingFeature/VehicleToListingFeatureEvent';
import { marketingToEvent } from '@tracking/Schemas/Marketing/MarketingToEvent';
import { userContextToEvent } from '@tracking/Schemas/UserContext/UserContextToEvent';

interface Props {
  searchParams: SearchParamsType;
  userId: string | null | undefined;
  formState: LeadFormState;
  listingId: string | null;
  source?: string;
  genericVehicleListings: GenericVehicleListingSubset | Vehicle;
  pageType: string;
}

export async function generateFavouritesTrackingData({
  searchParams,
  userId,
  formState,
  listingId,
  genericVehicleListings,
  pageType,
}: Props): Promise<Record<string, TrackingDataType>> {
  const marketingDataObj = {
    marketingValues: {
      ...searchParams,
    },
  };

  const userData = {
    userId,
    values: { ...formState },
  };

  const addFavouritesObj = {
    favouriteSource: pageType,
    listingId,
  };

  const vehicleListingSubset = await extractVehicleListingSubset(genericVehicleListings);
  const vehicleListingFeatureSubset = await extractVehicleListingFeatureSubset(
    genericVehicleListings,
  );

  // event actions
  const addedFavouritesEvent = addFavouritesToEvent(addFavouritesObj);

  // event entities
  const userEvent = userContextToEvent(userData);
  const listingEvent = vehicleToListingEvent(vehicleListingSubset);
  const marketingEvent = marketingToEvent(marketingDataObj);
  const listingFeatureEvent = vehicleToListingFeatureEvent(vehicleListingFeatureSubset);

  const contextStart = [listingEvent, listingFeatureEvent, userEvent, marketingEvent];

  return {
    addFavourites: { event: addedFavouritesEvent, context: contextStart },
  };
}
