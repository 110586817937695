'use client';

import { openModalAtom } from '@core/Atoms/Modal/Modal.atom';
import { Locale } from '@core/Entities/Locale/Locale.entity';
import { atom } from 'jotai';

import { LeadForm } from 'Components/LeadForms/LeadForm/LeadForm';

import { leadFormIdAtom } from './LeadFormId.atom';
import { leadFormStringsAtom } from './LeadFormStrings.atom';

export const openMessageDealerModalAtom = atom(null, (get, set, locale: Locale) => {
  const strings = get(leadFormStringsAtom);
  set(leadFormIdAtom, 'message-dealer');
  set(openModalAtom, <LeadForm strings={strings} locale={locale} />, {
    header: strings.messageDealerButton,
  });
});
