import { asMessages } from '@core/Utils/Intl/AsMessages';

const commonLeadFormScope = 'containers.leadform';
const messageDealerFormScope = '.messageDealer';
const historyCheckFormScope = '.historyCheck';

const commonLeadFormMessages = {
  privacyPolicy: 'privacy policy',
  privacyPolicyCaption: 'By submitting, you agree to our',
  vwfsPrivacyPolicy: 'VW Bank',
  andVwfs: 'et de',
  firstNameLabel: 'First name',
  lastNameLabel: 'Last name',
  fullNameLabel: 'Full name',
  emailAddressLabel: 'Email',
  phoneNumberLabel: 'Phone number',
  postalCodeLabel: 'Postcode',
  optionalMessageLabel: 'Any message? (optional)',
  invalidErrorMessage: 'This field is invalid',
  requiredErrorMessage: 'This is a required field.',
  invalidFullNameErrorMessage: 'Please enter your full name',
  dealerClosedErrorMessage: `La concession n'est pas ouverte à cette date`,
  dateTooFarErrorMessage: 'Selected date too far in the future',
  similarCarsHeader: 'Enquire on similar cars',
  oneClickCheckbox:
    'Enquire on similar cars - I am happy to be contacted by up to 4 dealers with similar cars matching search by phone or email, accepting Heycar',
  oneClickAndDriftRockCheckbox:
    'Enquire on similar cars - I am happy to be contacted by up to 4 used car dealers with similar cars & 1 new car dealer with similar cars matching search by phone or email, accepting Heycar',
  infoPopoverSimilarCars: `I’m interested in heycar’s recommendations. I give heycar my consent to enquire with {dealers} on similar cars that match my search.`,
  infoPopoverSimilarCarsDriftrock: `If you submit the form, {dealers} & 1 new car manufacturer on similar cars that match your search will contact you. The new car partner will be one of the following:`,
  infoPopoverDriftrock:
    'If you submit the form, 1 new car manufacturer on similar cars that match your search will contact you. The new car partner will be one of the following:',
  driftrockCheckboxLabel:
    'Enquire on similar cars - I am happy to be contacted by up to 1 new car dealer with similar cars matching search by phone or email, accepting Heycar',
  oneClickTerms: 'terms & conditions',
  oneClickOptionalLabel: '(optional)',
  reCaptchaLabel:
    'This site is protected by reCAPTCHA and the Google <privacyPolicyLink>{privacyPolicyLabel} </privacyPolicyLink> and <termsOfService>{label}</termsOfService> apply.',
  leadFormError: 'Something went wrong. Please try again',
  call: 'Call',
  reCaptchaTandC: 'Terms of Service',
  reCaptchaPrivacyPolicy: 'privacy policy',
  automaticCallBackPrefHeader: 'When would you like to be contacted by the dealer ?',
  automaticCallBackPrefOption1: 'As soon as possible',
  automaticCallBackPrefOption2: 'Dans la journée',
} as const;

const historyCheckFormMessages = {
  reportNotAvailable: 'The report is not available',
  contactDealerForInfo: 'Please contact the dealer for more information',
  sendMessage: 'Send a message',
  historyCheckModalHeader: 'History of Vehicle',
  successHistoryCheckModalDescription: 'We will send you the information shortly',
  successHistoryCheckModalBackButton: 'Return to vehicle page',
  loadingHistoryCheckModalDescription: 'We are checking the history of the vehicle',
  errorHistoryCheckModalHeadLine: 'An error has occurred',
  errorHistoryCheckModaldescription: 'Please try again later or contact the dealer',
  historyCheckModalCallDealerButton: 'Call dealer',
} as const;

const dealerFormMessages = {
  bookAppointmentAccordionHeader: 'Book an appointment',
  bookAppointmentCaption:
    'This car is a rare find! Increase your chances of securing it by booking an appointment',
  bookAppointmentDate: 'Choose a date',
  bookAppointmentPreferredTime: 'Preferred time',
  bookAppointmentKaisaTime: 'Programmer mon appel',
  bookAppointmentMorning: 'Morning',
  titleLabel: 'Titre',
  bookAppointmentAfternoon: 'Afternoon',
  dealerFormHeader: 'Fill in the form and the dealer will contact you',
  enrichmentTextHeaderClose: 'Hide details',
  enrichmentTextHeaderOpen: 'Add details',
  marketingOptIn: 'Keep me updated by email with the latest advice, news, and offers from heycar.',

  saveTimeLaterHeader: 'Save time later by completing a few extra steps now',
  successModalMessageDescription:
    'The dealer has received your details and will be in touch as soon as possible.',
  successLeasingDescription:
    'The heycar sales team will be in touch to talk through your options to lease.',
  successModalMessageHeadline: 'Thanks!',
  successModalSimilarCars: 'See similar cars',
  successModalBackButton: 'Back to search',
  preApprovalStartButton: 'Obtenir ma réponse',
  thankYouCloseButton: 'Non merci',
  financePreApprovalError:
    'Le formulaire de réponse de principe immédiate n’est pas disponible pour le moment. Veuillez réessayer ultérieurement.',
  preApprovalTitle: 'Êtes-vous éligible pour ce prêt? ',
  preApprovalSubTitle:
    'Gagnez du temps en concession en obtenant dès à présent une réponse de principe immédiate.',
  successModalHeader: 'Message Dealer',
  loadingModalMessageHeadline: 'We are sending your message to the dealer',
  loadingLeasingDescription: 'We are sending your message to the heycar sales team',
  errorModalHeader: 'Submission error ',
  errorModalMessageHeadline: 'Sorry! Something went wrong',
  errorModalMessageDescription: 'Please try again or enquire on another car.',
  messageDealerButton: 'Message dealer',
  messageDealerHeader: 'Message Dealer',
  messageLeasingHeader: 'Message Us',
  requestCallbackButton: 'Request callback',
  scheduleCallBack: 'Programmer mon appel',
  requestVideo: 'Request video',
  requestImages: 'Request images',
  financeQuoteAccordionHeader: 'Your buying options',
  financeQuoteCheckbox: 'Send my finance terms',
  financeQuoteCheckboxMonthly: '/month',
  financeQuoteDeposit: 'Deposit',
  financeQuoteApr: 'APR',
  financeQuoteTerm: 'Term',
  financeQuoteMileage: 'Mileage',
  financeQuoteFinalPayment: 'Opt. final payment',
  financeQuoteDepContribution: 'Dep. contribution',
  financeQuoteMonth: 'Months',
  financeQuoteInsurance: 'Insurance',
  financeQuoteRenaultTcs: `En cliquant « Envoyer », je déclare avoir lu la politique de confidentialité de http://heycar.com/fr  et je comprends que mes données soient transmises aux partenaires de heycar pour une offre de financement.`,
  financeQuoteVwfsTcs: `J'autorise heycar à transmettre mes informations au concessionnaire et à Volkswagen Bank GmbH France pour recevoir des offres personnalisées`,
  reservationTitle: 'Not secured yet',
  reservationSubtitle:
    'Don’t want to miss out on this car? Click here to reserve it now with only £99 reserve fee.',
  reservationCta: 'Reserve now',
  reservationCheckboxTitle: 'Not secured yet',
  reservationCheckboxSubtitle:
    'Don’t want to miss out on this car? Click here to request to reserve the car.',
  optional: 'Optional',
  reserveNowDontMissOut: "Don't miss out on this car by reserving it now for free",
  reserveNowTitle: 'How does reserving a car work?',
  confirmReservationHold: 'Confirm reservation with £99 hold on your card',
  carIsReserved: 'The car is reserved for 48 hours, if available',
  chooseToBuyOrCancel: 'Choose to buy or cancel at no cost',
  findOutMore: 'Find out more',
  newFeature: 'New feature',
  new: 'New',

  partExchangeAccordionHeader: 'Exchange your car',
  partExchangeCaption: 'Share details of your car for part-exchange with the dealer',
  partExchangeCarValue: 'We think your car is worth',
  partExchangeErrorMileage: 'Mileage must be a number',
  partExchangeMileageLabel: 'Mileage',
  partExchangeNotYourCar: 'Not your car?',
  partExchangePreFilledCaption:
    'Here is your valuation. You can share those details with the dealer.',
  partExchangePreFilledCheckbox: 'Send my valuation to the dealer',
  partExchangeRegistrationLabel: 'Enter reg number',
  partExchangeRequiredReg: 'Registration is required',
  partExchangeRequiredMileage: 'Mileage is required',
  partExchangeYourCar: 'Your car:',
} as const;

// to discuss how to make privacy policy and Terms of Service text dynamic as well
const commonLeadFormDefinition = asMessages(commonLeadFormScope, commonLeadFormMessages, {
  privacyPolicy: (
    <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">
      privacy policy
    </a>
  ),
  termsOfService: (
    <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">
      Terms of Service
    </a>
  ),
});

const historyCheckDefinition = asMessages(
  `${commonLeadFormScope}${historyCheckFormScope}`,
  historyCheckFormMessages,
);
const dealerFormDefinition = asMessages(
  `${commonLeadFormScope}${messageDealerFormScope}`,
  dealerFormMessages,
);

export const leadformMessages = {
  ...commonLeadFormDefinition.messages,
  ...dealerFormDefinition.messages,
  ...historyCheckDefinition.messages,
};

export const defaultLeadformMessages = {
  ...commonLeadFormDefinition.defaultMessages,
  ...dealerFormDefinition.defaultMessages,
  ...historyCheckDefinition.defaultMessages,
};

export type LeadformMessages = typeof defaultLeadformMessages;
export type LeadFormMessagesKeys = keyof LeadformMessages;
