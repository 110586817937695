import { ReactNode } from 'react';

import { LeadFormState } from '@core/Atoms/Pdp/FormState.atom';
import { Vehicle } from '@core/Entities/Vehicle/Vehicle.entity';
import { VehicleDealerOpeningTime } from '@core/Entities/Vehicle/VehicleDealerOpeningTime.entity';
import { isBusinessHours } from '@core/Utils/isBusinessHours/isBusinessHours';
import { Calculation } from '@fincalc/Entities/Calculation.entity';

import { CallBack } from '@gds/Icons';
import { Call } from '@gds/Icons/Paths/Call';
import { AllFlags } from '@growthBookExperimentation/Entities/FlagKeys.entities';

import { StringsType } from 'Components/LeadForms/LeadForm/LeadForm.entity';

export type LeadCtaConfigFunc<T> = (props: {
  flags?: AllFlags;
  isEcom?: boolean;
  vehicle: Vehicle;
  isVsp?: boolean;
  calculation?: Calculation | null;
  isFr?: boolean;
}) => T;

export type LeadCtaRequiredFields = (keyof LeadFormState)[];

export type callDealerCtasConfigValue = {
  labelId: string;
  shouldShowCta: (props: { openingTimes: VehicleDealerOpeningTime[]; flags?: AllFlags }) => boolean;
  variant: (props: { isEcom: boolean; isFr: boolean }) => 'outlined' | 'contained' | 'link';
  requiredFields?: () => LeadCtaRequiredFields;
  buttonIcon?: ReactNode;
  onErrorModalAction?: (strings: StringsType) => ReactNode;
  requestCallback: (
    dealerNumberError: boolean,
    openingTimes?: VehicleDealerOpeningTime[],
  ) => boolean;
  requestCallbackButtonLabel: (
    strings: StringsType,
    isSticky: boolean,
    isEcom: boolean,
    hasReserveNow?: boolean,
  ) => string;
  requestCallbackLeftIcon: (
    isSticky: boolean,
    isEcom: boolean,
    hasReserveNow?: boolean,
    isVsp?: boolean,
  ) => ReactNode | null;
  shouldShowRequestCallbackModal: (windowInnerWidth: number, isSticky: boolean) => boolean;
  shouldShowPhoneNumberError: (dealerNumberError: boolean, isSticky: boolean) => boolean;
  showButtonText?: (props: {
    isVsp?: boolean;
    isEcom?: boolean;
    isSticky?: boolean;
    isFr?: boolean;
  }) => boolean | undefined;
};

export type CtaPlacement = 'pdp_call-dealer' | 'hov_call-dealer';
export type CallDealerCtasConfig = Record<CtaPlacement, callDealerCtasConfigValue>;

export type callDealerCtasConfigArray = Array<[CtaPlacement, callDealerCtasConfigValue]>;

export const callDealerCtasConfig = {
  'pdp_call-dealer': {
    labelId: 'callDealerButton',
    shouldShowCta: ({ openingTimes, flags }) =>
      !flags?.callbackCtaPublicHoliday && isBusinessHours(openingTimes),
    variant: ({ isEcom, isFr }) => (isFr && !isEcom ? 'contained' : 'outlined'),
    showButtonText: ({ isSticky, isVsp, isEcom }) =>
      !isSticky || (isVsp && isSticky) || (isSticky && !isEcom),
    buttonIcon: <Call />,
    requestCallback: dealerNumberError => dealerNumberError,
    requestCallbackLeftIcon: (isSticky, isEcom, isVsp, hasReserveNow) => {
      return (isSticky && isEcom && !isVsp) || hasReserveNow ? <CallBack /> : null;
    },
    shouldShowRequestCallbackModal: (windowInnerWidth, isSticky) =>
      windowInnerWidth && windowInnerWidth < 768 && isSticky ? true : false,
    shouldShowPhoneNumberError: (dealerNumberError, isSticky) => dealerNumberError && !isSticky,
    requestCallbackButtonLabel: (strings, isSticky, isEcom, hasReserveNow) =>
      (isSticky && isEcom) || hasReserveNow ? '' : strings.requestCallbackButton,
  },

  'hov_call-dealer': {
    labelId: 'historyCheckModalCallDealerButton',
    shouldShowCta: () => true,
    variant: () => 'contained',
    showButtonText: ({ isSticky, isVsp, isEcom }) =>
      !isSticky || (isVsp && isSticky) || (isSticky && !isEcom),
    buttonIcon: <Call />,
    requestCallback: (dealerNumberError, openingTimes) =>
      dealerNumberError || !isBusinessHours(openingTimes),
    requestCallbackLeftIcon: () => <CallBack />,
    shouldShowPhoneNumberError: () => false,
    requestCallbackButtonLabel: strings => strings.requestCallbackButton,
    shouldShowRequestCallbackModal: () => true,
  },
} as const satisfies CallDealerCtasConfig;
